import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { CarBrand, CarModel, CarType } from '../../models/provisioning';
import { MeasurementUnit } from '../../models/measurement-unit';

@Injectable({
  providedIn: 'root',
})
export class GlobalStoreService {
  readonly deviceId = new BehaviorSubject<string | undefined>(undefined);
  readonly installationPage = new BehaviorSubject<string[] | undefined>(undefined);

  // Device installation status
  readonly isDeviceSetupComplete = new BehaviorSubject<boolean | undefined>(undefined);
  readonly isProvisioningComplete = new BehaviorSubject<boolean | undefined>(undefined);
  readonly isPairButtonComplete = new BehaviorSubject<boolean | undefined>(undefined);
  readonly isCalibrationComplete = new BehaviorSubject<boolean | undefined>(undefined);

  // Vehicle model information
  readonly vehicleType = new BehaviorSubject<CarType | undefined>(undefined);
  readonly vehicleVendor = new BehaviorSubject<CarBrand | undefined>(undefined);
  readonly vehicleModel = new BehaviorSubject<CarModel | undefined>(undefined);
  readonly licencePlate = new BehaviorSubject<string | undefined>(undefined);

  // Vehicle mounting information
  readonly camToFront = new BehaviorSubject<string | undefined>(undefined);
  readonly camToGround = new BehaviorSubject<string | undefined>(undefined);
  readonly camToLeft = new BehaviorSubject<string | undefined>(undefined);
  readonly camToRight = new BehaviorSubject<string | undefined>(undefined);
  readonly camToRearAxle = new BehaviorSubject<string | undefined>(undefined);
  readonly widthOfVehicle = new BehaviorSubject<string | undefined>(undefined);
  readonly tireDiameter = new BehaviorSubject<string | undefined>(undefined);
  readonly measurementUnit = new BehaviorSubject<MeasurementUnit | undefined>(undefined);

  readonly isTokenRefreshing = new BehaviorSubject<boolean | undefined>(undefined);

  constructor(private readonly localStorage: LocalStorageService) {
    this.deviceId.next(this.localStorage.get('deviceId') ?? undefined);
    this.installationPage.next(this.localStorage.get('installationPage') ?? undefined);

    this.isDeviceSetupComplete.next(this.localStorage.get('isDeviceSetupComplete') ?? undefined);
    this.isProvisioningComplete.next(this.localStorage.get('isProvisioningComplete') ?? undefined);
    this.isCalibrationComplete.next(this.localStorage.get('isCalibrationComplete') ?? undefined);
    this.isPairButtonComplete.next(this.localStorage.get('isPairButtonComplete') ?? undefined);

    this.vehicleType.next(this.localStorage.get('vehicleType') ?? undefined);
    this.vehicleVendor.next(this.localStorage.get('vehicleVendor') ?? undefined);
    this.vehicleModel.next(this.localStorage.get('vehicleModel') ?? undefined);
    this.licencePlate.next(this.localStorage.get('licencePlate') ?? undefined);

    this.camToFront.next(this.localStorage.get('camToFront') ?? undefined);
    this.camToGround.next(this.localStorage.get('camToGround') ?? undefined);
    this.camToLeft.next(this.localStorage.get('camToLeft') ?? undefined);
    this.camToRight.next(this.localStorage.get('camToRight') ?? undefined);
    this.camToRearAxle.next(this.localStorage.get('camToRearAxle') ?? undefined);
    this.widthOfVehicle.next(this.localStorage.get('widthOfVehicle') ?? undefined);
    this.tireDiameter.next(this.localStorage.get('tireDiameter') ?? undefined);
    this.measurementUnit.next(this.localStorage.get('measurementUnit') ?? undefined);

    this.isTokenRefreshing.next(this.localStorage.get('isTokenRefreshing') ?? undefined);

    this.deviceId.subscribe((val) => this.localStorage.setOrRemove('deviceId', val));
    this.installationPage.subscribe((val) => this.localStorage.setOrRemove('installationPage', val));

    this.isDeviceSetupComplete.subscribe((val) => this.localStorage.setOrRemove('isDeviceSetupComplete', val));
    this.isProvisioningComplete.subscribe((val) => this.localStorage.setOrRemove('isProvisioningComplete', val));
    this.isCalibrationComplete.subscribe((val) => this.localStorage.setOrRemove('isCalibrationComplete', val));
    this.isPairButtonComplete.subscribe((val) => this.localStorage.setOrRemove('isPairButtonComplete', val));

    this.vehicleType.subscribe((val) => this.localStorage.setOrRemove('vehicleType', val));
    this.vehicleVendor.subscribe((val) => this.localStorage.setOrRemove('vehicleVendor', val));
    this.vehicleModel.subscribe((val) => this.localStorage.setOrRemove('vehicleModel', val));
    this.licencePlate.subscribe((val) => this.localStorage.setOrRemove('licencePlate', val));

    this.camToFront.subscribe((val) => this.localStorage.setOrRemove('camToFront', val));
    this.camToGround.subscribe((val) => this.localStorage.setOrRemove('camToGround', val));
    this.camToLeft.subscribe((val) => this.localStorage.setOrRemove('camToLeft', val));
    this.camToRight.subscribe((val) => this.localStorage.setOrRemove('camToRight', val));
    this.camToRearAxle.subscribe((val) => this.localStorage.setOrRemove('camToRearAxle', val));
    this.widthOfVehicle.subscribe((val) => this.localStorage.setOrRemove('widthOfVehicle', val));
    this.tireDiameter.subscribe((val) => this.localStorage.setOrRemove('tireDiameter', val));
    this.measurementUnit.subscribe((val) => this.localStorage.setOrRemove('measurementUnit', val));

    this.isTokenRefreshing.subscribe((val) => this.localStorage.setOrRemove('isTokenRefreshing', val));
  }
}
