<ng-content select="[alert]"></ng-content>
<div
  class="page-content"
  [ngClass]="{ 'page-content-with-footer': footerPresent, 'page-content-footer-alert-fix': footerAlertFix }"
>
  <div class="page-inner mt-4">
    <div class="heading-container">
      <div *ngIf="hasStepCounter" class="index-of-total -size-s">
        {{
          'GENERAL.indexOfTotal'
            | translate
              : {
                  index: currentStepIndex,
                  total: totalStepsCount,
                }
        }}
      </div>
      <ng-content select="[heading]"></ng-content>
      <ng-content select="h1"></ng-content>
      <ng-content select="h2"></ng-content>
      <ng-content select="h3"></ng-content>
      <ng-content select="h4"></ng-content>
      <ng-content select="h5"></ng-content>
      <ng-content select="h6"></ng-content>

      <h4 *ngIf="displayRouteTitle && titleKey" [class.mt-8]="!hasStepCounter">
        {{ titleKey | translate }}
      </h4>
    </div>

    <div class="content-container" [class.page-content-padding]="pageContentPadding">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>

<div *ngIf="footerPresent" class="footer-container bg-white">
  <div class="button-container">
    <div *ngIf="!singleButton">
      <button
        *ngIf="backwardButtonPresent && backwardRoute === undefined"
        backButton
        class="a-button a-button--secondary -without-icon w-full"
      >
        <div class="a-button__label">{{ backwardButtonLabelKey | translate }}</div>
      </button>
      <button
        *ngIf="backwardButtonPresent && backwardRoute !== undefined"
        [routerLink]="backwardRoute"
        class="a-button a-button--secondary -without-icon w-full"
      >
        <div class="a-button__label">{{ backwardButtonLabelKey | translate }}</div>
      </button>
    </div>
    <div [class.single-button]="singleButton">
      <button
        type="button"
        *ngIf="forwardButtonPresent"
        [class]="'a-button a-button--' + forwardButtonStyle + ' -without-icon w-full'"
        [disabled]="forwardButtonDisabled"
        [routerLink]="forwardRoute"
        data-test="forward-button"
        (click)="forwardButtonClick.emit()"
      >
        <div class="a-button__label">{{ forwardButtonLabelKey | translate }}</div>
      </button>
    </div>
  </div>
  <page-indicator *ngIf="showPageIndicator" class="page-indicator"></page-indicator>
</div>
<ng-content select="[footer]"></ng-content>
