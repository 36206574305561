import { BoschIcon } from 'src/app/models/icons';
import { InfoMessageType } from 'src/app/models/info-message';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
})
export class InfoMessageComponent {
  @Input()
  type: InfoMessageType = InfoMessageType.INFO;

  @Input()
  icon: BoschIcon;

  @Input()
  classes: string;

  @Input()
  title: string;

  @Input()
  message: string;
}
