import { whenTruthy } from 'src/app/utils/utils';
import { Component, HostBinding, Input } from '@angular/core';
import { BoschIcon, UIIcon } from '../../../../models/icons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frok-icon',
  template: '',
})
export class FrokIconComponent {
  @Input() bosch?: BoschIcon;
  @Input() ui?: UIIcon;

  @Input() button = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class') classList?: string;

  @HostBinding('class') get classString(): string {
    return [
      'a-icon',
      whenTruthy(this.bosch, `boschicon-bosch-ic-${this.bosch}`),
      whenTruthy(this.ui, `ui-ic-${this.ui}`),
      whenTruthy(this.button, 'a-button__icon'),
    ]
      .concat(this.classList?.split(' ') ?? [])
      .filter(Boolean)
      .join(' ');
  }
}
