import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { StepPageData } from './step-page';

@Component({
  selector: 'app-step-page',
  templateUrl: './step-page.component.html',
  styleUrls: ['./step-page.component.scss'],
})
export class StepPageComponent {
  @Input() displayRouteTitle = true;

  @Input() footerPresent = true;

  @Input() forwardRoute: string | string[];
  @Input() backwardRoute: string | string[];
  @Input() forwardButtonLabelKey = 'GENERAL.next';
  @Input() forwardButtonStyle = 'primary';
  @Input() forwardButtonPresent = true;
  @Input() forwardButtonDisabled = false;
  @Output() forwardButtonClick = new EventEmitter();

  @Input() backwardButtonPresent = true;
  @Input() singleButton = false;
  @Input() backwardButtonLabelKey = 'GENERAL.back';
  @Input() pageContentPadding = true;
  @Input() showPageIndicator = false;
  @Input() footerAlertFix = false;

  constructor(private readonly route: ActivatedRoute) {}

  get titleKey(): string | undefined {
    return (this.route.routeConfig?.data as StepPageData | undefined)?.titleKey;
  }

  get hasStepCounter(): boolean {
    return this.routeHasStepCounter(this.route.routeConfig);
  }

  get totalStepsCount(): number | undefined {
    return this.steps?.length;
  }

  get currentStepIndex(): number | undefined {
    const index = this.route.routeConfig && this.steps?.indexOf(this.route.routeConfig);

    if (index == null || index === -1) {
      return undefined;
    }

    return index + 1;
  }

  private get steps(): Route[] | undefined | null {
    return this.route.parent?.routeConfig?.children?.filter((route) => this.routeHasStepCounter(route));
  }

  private routeHasStepCounter(route: Route | null): boolean {
    return !!(route?.data as StepPageData | undefined)?.hasStepCounter;
  }
}
