import { DialogType, GenericDialogCallback } from 'src/app/models/dialog';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QrCodeComponent implements OnDestroy {
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  @Input() scanErrorMessage: string;
  @Input() isValid = true;

  @Output() scanSuccessEvent = new EventEmitter<string>();
  @Output() hasPermissionEvent = new EventEmitter<boolean>();

  readonly formatsEnabled: BarcodeFormat[] = [BarcodeFormat.QR_CODE];

  availableCameras: MediaDeviceInfo[] = [];
  selectedCamera?: MediaDeviceInfo;

  hasPermission = true;
  hasScanError = false;
  scanResult: string;

  constructor(private readonly dialogService: DialogService) {}

  onCamerasFound(cameras: MediaDeviceInfo[]): void {
    this.availableCameras = cameras;
  }

  onCameraChange(deviceId: string): void {
    this.selectedCamera = this.availableCameras.find((x) => x.deviceId === deviceId);
  }

  onDeviceChange(event: MediaDeviceInfo): void {
    this.selectedCamera = event;
  }

  onHasPermission(value: boolean): void {
    this.hasPermission = value;
    this.hasPermissionEvent.emit(value);
  }

  onScanSuccess(result: string): void {
    this.hasScanError = false;
    this.scanSuccessEvent.emit(result);
  }

  onScanError(): void {
    this.hasScanError = true;
  }

  showDialog(): void {
    const close: GenericDialogCallback = () => {
      this.dialogService.close();
    };

    this.dialogService.display(
      'ENTERDEVICE.popover.title',
      'ENTERDEVICE.popover.description',
      true,
      true,
      false,
      false,
      undefined,
      undefined,
      undefined,
      close,
      undefined,
      undefined,
      DialogType.NEUTRAL
    );
  }

  ngOnDestroy(): void {
    this.scanner?.scanStop();
  }
}
