import { PartialConfig } from 'aws-rum-web/dist/es/orchestration/Orchestration';

export const environment = {
  development: true,
  production: false,
  apiBaseUrl: 'https://dev.bosch-ridecare.com',
  websocketUrl: 'wss://ws.dev.bosch-ridecare.com/installation',
  cookieDomain: '.viper.dev.bosch-ridecare.com',
  configS3Bucket: 'rcc-dev-device-config',
  awsRumApp: {
    applicationId: 'bdd490cd-bdd7-4c51-a118-23b8346a02f2',
    applicationVersion: '1.0.0',
    region: 'eu-central-1',
  },
  awsRumConfig: {
    sessionSampleRate: 1,
    guestRoleArn: 'arn:aws:iam::213279581081:role/dev-viper-installation-ui-CWRumClientRole-15615LASUDKOM',
    identityPoolId: 'eu-central-1:1af11ac0-3e75-4ea6-8665-178e6cb7ae05',
    endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
    telemetries: [
      ['errors', { stackTraceLength: 2000 }],
      'performance',
      ['http', { stackTraceLength: 2000, addXRayTraceIdHeader: false }],
    ],
    allowCookies: false,
    enableXRay: false,
    enableRumClient: true,
    disableAutoPageView: true,
  } as PartialConfig,
};
