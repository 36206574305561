<div class="mt-4 camera">
  <zxing-scanner
    #scanner
    class="scanner"
    [device]="selectedCamera"
    (camerasFound)="onCamerasFound($event)"
    (deviceChange)="onDeviceChange($event)"
    (permissionResponse)="onHasPermission($event)"
    (scanSuccess)="onScanSuccess($event)"
    (scanError)="onScanError()"
    [formats]="formatsEnabled"
    [torch]="true"
  >
  </zxing-scanner>
  <div class="success" [class.active]="scanResult">
    <frok-icon class="success-xxl" bosch="checkmark-frame" title="Success"></frok-icon>
    <div>Scan was successful</div>
  </div>
</div>

<!-- Camera has no permission or a scan error has occured -->
<div
  *ngIf="hasScanError || !hasPermission"
  class="a-notification -error"
  role="alert"
  aria-labelledby="notification-label-id"
>
  <div id="notification-label-id" class="a-notification__content">
    <span *ngIf="hasScanError && scanResult === undefined">{{ scanErrorMessage | translate }}</span>
    <span *ngIf="!hasPermission">{{ 'QRCODESCAN.noPermission' | translate }}</span>
  </div>
</div>

<!-- Camera has scanned successfully but code is invalid -->
<div *ngIf="!isValid" class="a-notification -error" role="alert" aria-labelledby="notification-label-id-second">
  <div id="notification-label-id-second" class="a-notification__content">
    {{ 'QRCODESCAN.scanInvalid' | translate }}
  </div>
</div>

<!-- Info message -->
<div>
  <a class="mt-3 block -size-ms cursor-pointer" data-test="where-to-find-qr" (click)="showDialog()">
    <frok-icon class="a-icon ui-ic-alert-info -size-m-important"></frok-icon>
    {{ 'ENTERDEVICE.whereCanIFind' | translate }}
  </a>
</div>

<!-- Camera selector -->
<div class="a-dropdown mt-4">
  <label for="selectCamera">{{ 'QRCODESCAN.selectCamera' | translate }}</label>
  <select
    #selectCamera
    id="selectCamera"
    [ngModel]="selectedCamera?.deviceId"
    (change)="onCameraChange(selectCamera.value)"
  >
    <option *ngFor="let camera of availableCameras" [value]="camera.deviceId">{{ camera.label }}</option>
  </select>
</div>
