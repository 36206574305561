import { Injectable } from '@angular/core';
import { CarBrand, CarModel, CarType } from '../../models/provisioning';
import { MeasurementUnit } from '../../models/measurement-unit';

export interface Pairs {
  /**
   * Stores the installation page route to resume an ongoing installation.
   */
  installationPage: string[];
  deviceId: string;
  authToken: string;
  isDeviceSetupComplete: boolean;
  isProvisioningComplete: boolean;
  isCalibrationComplete: boolean;
  isPairButtonComplete: boolean;
  vehicleType: CarType;
  vehicleModel: CarModel;
  vehicleVendor: CarBrand;
  licencePlate: string;
  camToFront: string;
  camToGround: string;
  camToLeft: string;
  camToRight: string;
  camToRearAxle: string;
  widthOfVehicle: string;
  tireDiameter: string;
  measurementUnit: MeasurementUnit;
  isTokenRefreshing: boolean;
  redirectUri: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private readonly window: Window) {}

  set<K extends keyof Pairs>(key: K, value: Pairs[K]): void {
    try {
      this.window.localStorage.setItem(key, JSON.stringify(value));
    } catch {
      // The local storage can throw an OOM exception especially on older Mobile Safari private tabs
    }
  }

  get<K extends keyof Pairs>(key: K, isEssential = true): Pairs[K] | null {
    const value = this.window.localStorage.getItem(key);

    try {
      return isEssential && value != null ? JSON.parse(value) : null;
    } catch {
      return null;
    }
  }

  remove(key: keyof Pairs): void {
    this.window.localStorage.removeItem(key);
  }

  setOrRemove<K extends keyof Pairs>(key: K, value?: Pairs[K]): void {
    if (value != null) {
      this.set(key, value);
    } else {
      this.remove(key);
    }
  }
}
