import { NavigationService } from 'src/app/services/navigation/navigation.service';

import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[backButton]',
})
export class BackButtonDirective {
  constructor(private readonly navigation: NavigationService) {}

  @HostListener('click')
  onClick(): void {
    this.navigation.back();
  }
}
