import { BoschIcon } from './icons';

export enum DialogType {
  NEUTRAL = '',
  ERROR = '--error',
  INFO = '--info',
  WARNING = '--warning',
  SUCCESS = '--success',
}

export type GenericDialogCallback = () => void;

export interface GenericDialog {
  visible: boolean;
  type: DialogType;
  titleLabelKey: string;
  descriptionLabelKey: string;
  showBackdrop: boolean;
  showClose: boolean;
  alignBottom: boolean;
  fullWidth: boolean;
  titleIcon?: BoschIcon;
  okLabelKey?: string;
  cancelLabelKey?: string;
  closeButtonClick?: GenericDialogCallback;
  okButtonClick?: GenericDialogCallback;
  cancelButtonClick?: GenericDialogCallback;
  okButtonClass?: string;
  cancelButtonClass?: string;
}
