import { Observable, Subject } from 'rxjs';
import { DialogType, GenericDialog, GenericDialogCallback } from 'src/app/models/dialog';
import { BoschIcon } from 'src/app/models/icons';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private activeDialogs: GenericDialog[] = [];
  private _dialog: Subject<GenericDialog> = new Subject<GenericDialog>();
  dialog$: Observable<GenericDialog> = this._dialog.asObservable();

  /**
   * Displays a new dialog with the given parameters as soon as possible
   * There can only be one dialog visible at a time,
   * because of this there is a queue in case multiple dialogs are created before the previous one was closed
   *
   * @param titleLabelKey
   * @param descriptionLabelKey
   * @param showBackdrop
   * @param showClose
   * @param alignBottom
   * @param fullWidth
   * @param titleIcon
   * @param okLabelKey
   * @param cancelLabelKey
   * @param closeButtonClick
   * @param okButtonClick
   * @param cancelButtonClick
   * @param type
   * @param okButtonClass
   * @param cancelButtonClass
   */
  display(
    titleLabelKey: string,
    descriptionLabelKey: string,
    showBackdrop = false,
    showClose = false,
    alignBottom = false,
    fullWidth = false,
    titleIcon?: BoschIcon,
    okLabelKey?: string,
    cancelLabelKey?: string,
    closeButtonClick?: GenericDialogCallback,
    okButtonClick?: GenericDialogCallback,
    cancelButtonClick?: GenericDialogCallback,
    type: DialogType = DialogType.NEUTRAL,
    okButtonClass?: string,
    cancelButtonClass?: string
  ): void {
    const dialogData: GenericDialog = {
      visible: true,
      type,
      titleLabelKey,
      descriptionLabelKey,
      showBackdrop,
      showClose,
      alignBottom,
      fullWidth,
      titleIcon,
      okLabelKey,
      cancelLabelKey,
      closeButtonClick,
      okButtonClick,
      cancelButtonClick,
      okButtonClass,
      cancelButtonClass,
    };
    // add the new dialog to the queue
    this.activeDialogs.push(dialogData);
    // handle the next queue item
    this.handleNextDisplay();
  }

  /**
   * Displays the generic error dialog
   *
   * @param closeCallback
   */
  displayGenericError(closeCallback: GenericDialogCallback): void {
    this.display(
      'ERROR.generic.title',
      'ERROR.generic.description',
      true,
      false,
      false,
      false,
      'alert-error',
      'ERROR.generic.ok',
      undefined,
      undefined,
      closeCallback,
      undefined,
      DialogType.ERROR
    );
  }

  /**
   * Closes the active dialog, if any
   */
  close(): boolean {
    if (this.activeDialogs && this.activeDialogs[0]?.visible) {
      // set the dialog visible status to false, so it's not displayed anymore
      this.activeDialogs[0].visible = false;
      this._dialog.next(this.activeDialogs[0]);
      // delete the closed dialog from the queue
      this.activeDialogs.splice(0, 1);
      this.handleNextDisplay();
      return true;
    }
    return false;
  }

  private handleNextDisplay(): void {
    // only emit dialog data if there's one visible item in the queue
    // this way we avoid submitting all the item in the queue at once
    if (this.activeDialogs[0]?.visible && this.activeDialogs.length === 1) {
      this._dialog.next(this.activeDialogs[0]);
    }
  }
}
