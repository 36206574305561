// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const deviceIdPattern = /^[a-zA-Z]+/;
export const serialNumberPattern = /^\d{16}$/;
export const numericPatternWithSpaces = /^\s*?\d+(\.\d+)?\s*?$/;
export const noWhitespaces = /[^\s\\]/;

export function downloadLicenseDisclosureDocument(): void {
  window.open('https://bosch-visual-data-collection.com/oss4R2', 'WindowName', 'noopener');
}

export function whenTruthy<T, U>(value: T, result: U): U | undefined {
  return value ? result : undefined;
}

export function validateDeviceId(deviceId: string): boolean {
  return deviceIdPattern.test(deviceId);
}

export function validateSerialNumber(serialNumber: string): boolean {
  return serialNumberPattern.test(serialNumber);
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * This validator compare the valueToCompare parameter with the introduced value
 * If the values are matching an error is returned, else success (null)
 * @param valueToCompare
 */
export function rejectSameContentValidator(valueToCompare: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return value === valueToCompare ? { sameContent: true } : null;
  };
}
