<div>
  <form class="flex-form" [formGroup]="deviceForm">
    <div class="flex-form mt-4 p-4 bg-bosch-gray-95">
      <div class="m-popover__paragraph" [innerHTML]="'ENTERDEVICE.popover.description' | translate"></div>
      <div class="a-text-field">
        <label for="device-id">{{ 'ENTERDEVICE.deviceIdLabel' | translate }}</label>
        <input
          class="w-full"
          type="text"
          id="device-id"
          name="deviceId"
          data-test="device-id"
          autofocus
          placeholder="{{ 'ENTERDEVICE.deviceIdInput' | translate }}"
          formControlName="deviceIdInput"
        />
      </div>
      <div
        *ngIf="deviceIdInput.invalid && (deviceIdInput.dirty || deviceIdInput.touched)"
        class="a-notification -error"
        role="alert"
        data-test="device-id-error"
        aria-labelledby="notification-label-id"
      >
        <div
          *ngIf="deviceIdInput.errors?.pattern || deviceIdInput.errors?.required"
          id="notification-label-id"
          class="a-notification__content"
        >
          {{ 'ENTERDEVICE.errorMessage.required' | translate }}
        </div>
      </div>
    </div>
    <button
      type="button"
      class="a-button a-button--secondary -without-icon w-full mt-8"
      data-test="go-to-scan-code"
      (click)="onScanCode()"
    >
      <div class="a-button__label">{{ 'ENTERDEVICE.scanCode' | translate }}</div>
    </button>
    <button
      type="button"
      class="a-button a-button--primary -without-icon w-full mt-3"
      [disabled]="deviceIdInput.invalid"
      data-test="forward-button"
      (click)="onSubmit()"
    >
      <div class="a-button__label">{{ 'GENERAL.done' | translate }}</div>
    </button>
  </form>
</div>
