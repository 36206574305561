import { StartUpService } from 'src/app/services/startup/startup.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-up',
  templateUrl: './start-up.component.html',
  styleUrls: ['./start-up.component.scss'],
})
export class StartUpComponent implements OnInit {
  constructor(private readonly startupService: StartUpService) {}

  async ngOnInit(): Promise<void> {
    await this.startupService.init();
  }
}
