import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { GlobalStoreService } from '../global-store/global-store.service';
import { DeviceValidationRequest, DeviceValidationResponse } from '../../models/device-validation';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly globalStoreService: GlobalStoreService
  ) {}

  authenticate(request: DeviceValidationRequest): Observable<DeviceValidationResponse> {
    return this.validateDevice(request).pipe(
      tap((response: DeviceValidationResponse) => {
        this.localStorageService.set('authToken', response.token);
        this.globalStoreService.isTokenRefreshing.next(false);
      })
    );
  }

  getAuthToken(): string | null {
    return this.localStorageService.get('authToken');
  }

  removeAuthentication(): void {
    this.localStorageService.remove('authToken');
  }

  validateDevice(info: DeviceValidationRequest): Observable<DeviceValidationResponse> {
    return this.http.post<DeviceValidationResponse>(`${environment.apiBaseUrl}/validation/viper/qrcode`, info);
  }
}
