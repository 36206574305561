import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BaseComponent } from './components/base/base.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { EnterDeviceComponent } from './components/enter-device/enter-device.component';
import { FrokIconComponent } from './components/frok-icon/frok-icon.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { NeedHelpComponent } from './components/need-help/need-help.component';
import { QrCodeComponent } from './components/qrcode/qrcode.component';
import { RcSpinnerComponent } from './components/rc-spinner/rc-spinner.component';
import { StepPageComponent } from './components/step-page/step-page.component';
import { BackButtonDirective } from './directives/back-button/back-button.directive';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { StartUpComponent } from './components/start-up/start-up.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { HeaderComponent } from './components/header/header.component';

const components = [
  FrokIconComponent,
  RcSpinnerComponent,
  LoadingOverlayComponent,
  StepPageComponent,
  DialogComponent,
  NeedHelpComponent,
  InfoMessageComponent,
  QrCodeComponent,
  EnterDeviceComponent,
  HeaderComponent,
  GenericDialogComponent,
  StartUpComponent,
];

const pipes = [SafeHtmlPipe];

@NgModule({
  declarations: [BaseComponent, ...components, ...pipes, BackButtonDirective],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ZXingScannerModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
  ],
  exports: [CommonModule, TranslateModule, ...components, ...pipes, BackButtonDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
