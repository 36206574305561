import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss'],
})
export class NeedHelpComponent {
  @Input()
  marginClass = 'ml-4 mr-4';
}
