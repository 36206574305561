export enum CameraName {
  INTERIOR_CAMERA = 'InteriorCamera',
  FRONT_CAMERA = 'FrontCamera',
}

export enum ConnectionStatus {
  CLOSE = 0,
  OPEN = 1,
}

export enum Status {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum BluetoothPairingStatus {
  UNKNOWN = 'PAIRING_STATUS__UNKNOWN' /** Default unset value */,
  ACTIVE = 'PAIRING_STATUS__ACTIVE' /** Pairing active */,
  INACTIVE = 'PAIRING_STATUS__INACTIVE' /** Pairing not active */,
  TIMEOUT = 'PAIRING_STATUS__TIMEOUT' /** Timeout reached, from start command */,
  CANCELED = 'PAIRING_STATUS__CANCELED' /** Pairing canceled by device logic */,
  STOPPED = 'PAIRING_STATUS__STOPPED' /** Received stop command from backend */,
}

export enum EventType {
  BLUETOOTH_PAIRING = 'BluetoothButtonPairingEvent',
  CAMERAS_EVENT = 'CamerasEvent',
  CONNECTION_STATUS = 'ConnectionStatusEvent',
  OPERATION_MODE = 'OperationModeEvent',
  HEARTBEAT = 'HeartBeatEvent',
}

export enum CommandType {
  START_BLUETOOTH_PAIRING = 'StartBluetoothButtonPairingCommand',
  STOP_BLUETOOTH_PAIRING = 'StopBluetoothButtonPairingCommand',
  GET_CAMERAS = 'GetCamerasCommand',
  STOP_GET_CAMERAS = 'StopGetCamerasCommand',
  SUBSCRIBE_TOPIC = 'SubscribeTopic',
  PULL_TOPIC = 'PullTopic',
  HEARTBEAT = 'Heartbeat',
}

export enum TopicType {
  CONNECTION_STATUS = 'ConnectionStatus',
  OPERATION_MODE = 'OperationMode',
}
