import { Component } from '@angular/core';

import { DialogService } from '../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
})
export class GenericDialogComponent {
  constructor(readonly dialogService: DialogService) {}
}
