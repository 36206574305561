<ng-container *ngIf="dialogService.dialog$ | async as dialogData">
  <app-dialog
    *ngIf="dialogData.visible"
    [alignBottom]="dialogData.alignBottom"
    [fullWidth]="dialogData.fullWidth"
    [showClose]="dialogData.showClose"
    [okLabelKey]="dialogData.okLabelKey"
    [cancelLabelKey]="dialogData.cancelLabelKey"
    [descriptionLabelKey]="dialogData.descriptionLabelKey"
    [titleLabelKey]="dialogData.titleLabelKey"
    [showBackdrop]="dialogData.showBackdrop"
    [titleIcon]="dialogData.titleIcon"
    [wrapperClass]="'no-container'"
    [okButtonClass]="dialogData.okButtonClass"
    [cancelButtonClass]="dialogData.cancelButtonClass"
    [dialogType]="dialogData.type"
    (closeButtonClick)="dialogData.closeButtonClick ? dialogData.closeButtonClick() : undefined"
    (okButtonClick)="dialogData.okButtonClick ? dialogData.okButtonClick() : undefined"
    (cancelButtonClick)="dialogData.cancelButtonClick ? dialogData.cancelButtonClick() : undefined"
  ></app-dialog>
</ng-container>
