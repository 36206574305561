import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  headerTitle = new BehaviorSubject('');
  showBackButton = new BehaviorSubject<boolean>(false);
  backButtonUrl?: string;

  setTitle(title: string, options?: { showBackButton: boolean; backButtonUrl?: string }): void {
    this.headerTitle.next(title);
    this.showBackButton.next(!!options?.showBackButton);
    this.backButtonUrl = options?.backButtonUrl;
  }

  getTitle(): BehaviorSubject<string> {
    return this.headerTitle;
  }

  showBackButtonObservable(): Observable<boolean | undefined> {
    return this.showBackButton.asObservable();
  }
}
