import { DialogType } from 'src/app/models/dialog';
import { BoschIcon } from 'src/app/models/icons';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() dialogType: DialogType = DialogType.NEUTRAL;
  @Input() showBackdrop: boolean;
  @Input() showClose: boolean;
  @Input() titleLabelKey = 'GENERAL.next';
  @Input() titleIcon?: BoschIcon;
  @Input() descriptionLabelKey = 'GENERAL.next';
  @Input() okLabelKey?: string;
  @Input() cancelLabelKey?: string;
  @Input() alignBottom = false;
  @Input() fullWidth = false;
  @Input() wrapperClass = '';
  @Input() okButtonClass?: string;
  @Input() cancelButtonClass?: string;

  @Output() okButtonClick = new EventEmitter();
  @Output() cancelButtonClick = new EventEmitter();
  @Output() closeButtonClick = new EventEmitter();
}
