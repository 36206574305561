import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaces } from '../../../../utils/utils';

@Component({
  selector: 'app-enter-device',
  templateUrl: './enter-device.component.html',
  styleUrls: ['./enter-device.component.scss'],
})
export class EnterDeviceComponent {
  @Output() submitEvent = new EventEmitter<string | null>();
  @Output() scanCodeEvent = new EventEmitter<void>();

  readonly deviceIdInput = new FormControl('', {
    validators: [Validators.required, Validators.pattern(noWhitespaces)],
  });
  readonly deviceForm = new FormGroup({ deviceIdInput: this.deviceIdInput });

  onSubmit(): void {
    this.submitEvent.emit(this.deviceIdInput.value);
  }

  onScanCode(): void {
    this.scanCodeEvent.emit();
  }
}
