<div class="backdrop" *ngIf="showBackdrop"></div>
<div
  class="fixed wrapper {{ wrapperClass }}"
  data-test="dialog"
  [ngClass]="{ 'align-bottom': alignBottom, 'full-width': fullWidth }"
>
  <div class="no-padding m-dialog -detached -without-arrow">
    <div class="m-dialog__remark {{ dialogType }}"></div>
    <div class="a-box -floating">
      <div class="override-padding override-flex m-dialog__content content">
        <button
          type="button"
          class="a-button a-button--tertiary -without-label close-button"
          aria-label="accessibility label"
          data-test="close-button"
          (click)="closeButtonClick.emit()"
          *ngIf="showClose"
        >
          <frok-icon [button]="false" bosch="close" class="a-icon a-button__icon"></frok-icon>
        </button>
        <div
          class="m-dialog__headline"
          [ngClass]="{ '-with-icon': titleIcon }"
          id="frontend-kit-dialog-default-dialog-without-header-title"
        >
          <frok-icon [button]="false" [bosch]="titleIcon" *ngIf="titleIcon"></frok-icon>
          {{ titleLabelKey | translate }}
        </div>
        <div class="m-dialog__body" id="frontend-kit-dialog-default-dialog-without-header-description">
          <span [innerHTML]="descriptionLabelKey | translate | safeHtml"></span>
        </div>
        <div class="custom-margin override-flex m-dialog__actions">
          <button
            type="button"
            class="no-margin a-button a-button--primary -without-icon {{ okButtonClass }}"
            data-test="ok-button"
            (click)="okButtonClick.emit()"
            *ngIf="okLabelKey"
          >
            <div class="a-button__label">{{ okLabelKey | translate }}</div>
          </button>
          <button
            type="button"
            class="a-button a-button--secondary -without-icon {{ cancelButtonClass }}"
            data-test="cancel-button"
            (click)="cancelButtonClick.emit()"
            *ngIf="cancelLabelKey"
          >
            <div class="a-button__label">{{ cancelLabelKey | translate }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
